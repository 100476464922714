















































































































































import { LoadingStatusType } from "@/enums/enums";
import { NewObject } from "@/helper/shareHelper";
import { ActivityLogDetailModel } from "@/model/activitylog/activitylogDetailModel";
import { ActivityLogTableViewModel } from "@/model/activitylog/activityLogTableViewModel";
import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const StoreMerchant = namespace("Merchant");
const StoreActivityLog = namespace("ActivityLog");

@Component({ components: {} })
export default class ActivityLogTable extends Vue {
  private textSearch = "";
  /*private table: any = {
    header: [
      { text: "No", value: "no" },
      { text: "name", value: "name" },
      { text: "action", value: "action" },
      { text: "action date", value: "actiondate" },
      { text: "action status", value: "actionstatus" },
      { text: "description", value: "description" },
    ],
    detail: [],
  };*/
  private table: any = {
    header: [
      { text: "", value: "index" },
      { text: "name", value: "name" },
      { text: "action", value: "action" },
      { text: "action date", value: "actiondate" },
      { text: "action status", value: "actionstatus" },
      { text: "description", value: "description" },
      { text: "asana", value: "asana" },
      { text: "remark", value: "remark" },
    ],
    detail: [],
  };
  //private action: any = ["login", "swap package", "cancel package"];
  private action: any = [
    { actionValue: 1, text: "Login" },
    { actionValue: 2, text: "Swap package" },
    { actionValue: 3, text: "Cancel package" },
  ];
  private enabled = null;
  //private datefrom = new Date(this.newdate - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10);
  private dateCurrent = new Date();
  private thirdPreviosDate = this.dateCurrent.setDate(this.dateCurrent.getDate() - 3);
  private datefrom = new Date(this.thirdPreviosDate - new Date().getTimezoneOffset() * 60000)
    .toISOString()
    .substr(0, 10);
  private menufrom = false;
  private modalfrom = false;
  private menu2from = false;
  private dateto = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10);
  private menuto = false;
  private modalto = false;
  private menu2to = false;
  private pageNo = 1;
  private pageSize = 10;
  private actionValue = 0;

  private countItem = 0;
  private totalPage = 0;
  private pageNoList: Array<number> = [];
  private pageSizeList = [5, 10, 25, 50, 100];

  @StoreActivityLog.Getter
  private getActivityLogDetailListModel!: Array<ActivityLogDetailModel> | null;

  @StoreActivityLog.Action
  private DoGetActivityLogTable!: (activityLogTableViewModel: ActivityLogTableViewModel) => void;

  @StoreActivityLog.Getter
  private getActivityLogTableLoadingStatus!: LoadingStatusType;

  @StoreActivityLog.Getter
  private getCountItemTable!: number;

  @Watch("pageNo")
  pageNoChanged(newVal: number) {
    this.GetActivityLogTable(this.pageNo, this.pageSize);
  }

  @Watch("pageSize")
  pageSizeChanged(newVal: number) {
    this.GetActivityLogTable(1, this.pageSize);
  }

  @Watch("actionValue")
  actionDropdownChanged(newVal: number) {
    this.actionValue = newVal;
  }

  @Watch("getActivityLogTableLoadingStatus", { immediate: true })
  getActivityLogTableLoadingStatusChanged(newVal: LoadingStatusType) {
    if (newVal == LoadingStatusType.Success) {
      this.table.detail = [];
      if (this.getActivityLogDetailListModel && this.getActivityLogDetailListModel.length > 0) {
        this.getActivityLogDetailListModel.forEach((item: any) => {
          this.table.detail.push(NewObject(item));
        });
      }
      this.countItem = this.getCountItemTable;

      if (this.countItem === 0) {
        this.totalPage = 1;
      } else {
        this.totalPage = Math.ceil(this.getCountItemTable / this.pageSize);
      }
      this.pageNoList = [];
      for (let index = 0; index < this.totalPage; index++) {
        this.pageNoList.push(index + 1);
      }
    }
  }

  created() {
    this.GetActivityLogTable(this.pageNo, this.pageSize);
  }

  public GetActivityLogTable(pageNo = this.pageNo, pageSize = this.pageSize) {
    const activityLogTableViewModel: ActivityLogTableViewModel = {
      pageSize: pageSize,
      pageNo: pageNo,
      actionLog: this.actionValue,
      fromDate: this.datefrom,
      toDate: this.dateto,
      name: this.textSearch,
    };
    this.pageNo = pageNo;
    this.pageSize = pageSize;
    // this.DoGetActivityLogTable(activityLogTableViewModel);
  }
  public SearchActivityLog() {
    this.GetActivityLogTable(this.pageNo, this.pageSize);
  }

  public LabelNumber(index: number) {
    if (this.pageNo > 1) {
      return index + 1 + this.pageSize * (this.pageNo - 1);
    } else {
      return index + 1;
    }
  }
}
