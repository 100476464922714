






import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import ActivityLogTable from "./ActivityLogTable.vue";

@Component({ components: { ActivityLogTable } })
export default class ActivityLog extends Vue {}
